export enum WEB_LANGUAGE {
  CN = "zh-CN",
  EN = "en-US",
  HK = "zh-HK",
}

export enum WEB_THEME {
  LIGHT = "light",
  DARK = "dark",
}

export const LANGUAGE_STORE_KEY = "_web_kyc_language";

export const THEME_STORE_KEY = "_web_kyc_theme";
