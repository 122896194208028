import React, { Suspense } from "react";
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import { Loading } from "@/components/common/Loading";

const routes = [
  {
    path: "/friends",
    component: () => import("./page/Friends"),
  },
  {
    path: "/home",
    component: () => import("./page/Home"),
  },
  {
    path: "*",
    component: () => import("./page/NotFound"),
  },
];

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {routes.map((route) => {
        const { path, component } = route;
        return (
          <Route
            key={path}
            path={path}
            lazy={async () => {
              const { default: Component } = await component();
              return {
                element: (
                  <Suspense fallback={<Loading />}>
                    <Component />
                  </Suspense>
                ),
              };
            }}
          />
        );
      })}
    </Route>,
  ),
  {
    basename: "/",
  },
);

export default router;
