import { createContext, FC, useState, ReactNode, useContext, useEffect } from "react";
import { WEB_THEME } from "@/enums/global.enum";

interface ThemeContextValue {
  theme: WEB.Theme;
  toggleTheme: (defaultTheme?: WEB.Theme) => void;
}

const ThemeContext = createContext<ThemeContextValue>({
  theme: "light",
  toggleTheme: () => {},
});

export const useTheme = () => {
  return useContext(ThemeContext);
};

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<WEB.Theme>(WEB_THEME.DARK);

  const toggleTheme: ThemeContextValue["toggleTheme"] = (defaultTheme) => {
    const newTheme = defaultTheme || (theme === "light" ? "dark" : "light");
    setTheme(newTheme);
  };

  useEffect(() => {
    setTheme(WEB_THEME.DARK);
  }, []);

  useEffect(() => {
    const htmlDom = document.getElementsByTagName("body")?.[0];
    const beforeTheme = theme === "light" ? "dark" : "light";
    htmlDom.classList.remove(beforeTheme);
    htmlDom.classList.add(theme);
  }, [theme]);

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};
