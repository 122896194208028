import React from "react";
import ReactDOM from "react-dom/client";
import "amfe-flexible";
import { RouterProvider } from "react-router-dom";
import "antd-mobile/es/global";
import "./index.scss";
import "./reset.scss";
import AppWrapper from "./AppWrapper";
import router from "./router";
import { ThemeProvider } from "./context/ThemeContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const WebApp = window.Telegram.WebApp;

WebApp.ready();
WebApp.setHeaderColor("#000");
root.render(
  <ThemeProvider>
    <AppWrapper>
      <RouterProvider router={router} />
    </AppWrapper>
  </ThemeProvider>,
);
